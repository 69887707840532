<template>
  <div class="tableBox">
    <!-- 表格 -->
    <el-table :data="dataList" stripe style="width: 100%">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="客服名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="userNumber" label="新增客户个数" align="center">
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="toDetails(scope.row)">
            {{ scope.row.userNumber }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="allocNumber" label="分配资源个数" align="center">
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="toDetails(scope.row)">
            {{ scope.row.allocNumber }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="toDetails(scope.row)">
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'TableBox',
  components: {},
  data() {
    return {};
  },
  props: {
    dataList: Array,
    pagenum: Number,
    pageSize: Number,
    datetime: Array
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    },
    toDetails(row) {
      sessionStorage.setItem('customerInformationSearch', {
        time: this.datetime,
        createName: row.userName
      });
      this.$router.push({
        path: '/customerInformation'
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.tableBox {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>