<template>
  <div class="customerFollowRecordDetailBox" v-loading="loading">
    <headerTitle
      title="客户跟进"
      :titles="['查看详情']"
      :showBtn="true"
    ></headerTitle>
    <div class="detailDialogBox">
      <div class="flexs-alignCenter lineBox">
        <div>分配老师：{{ detailObj.teacherName || '' }}</div>
        <div>上次回访时间：{{ detailObj.createTime || '' }}</div>
        <div>提醒下次回访时间：{{ detailObj.revisitTime || '' }}</div>
      </div>
      <div class="flexs-alignCenter lineBox">
        <div>资源状态：{{ detailObj.customType | customTypeFilter }}</div>
        <div>联系类型：{{ detailObj.contactType | contactTypeFilter }}</div>
      </div>
      <div class="flexs-alignCenter lineBox">
        <div>签约金额：{{ detailObj.planBidAmount || 0 }}元</div>
        <div>签约时间：{{ detailObj.planTime || '' }}</div>
        <div>竞签机构：{{ detailObj.institution || '' }}</div>
      </div>
      <div class="lineBox">联系内容：{{ detailObj.content || '' }}</div>
      <div class="detailLine flexs">
        <div class="detailTitle">微信聊天记录：</div>
        <div v-if="detailObj.wxText">
          <!-- <div class="link">
            {{ detailObj.wxText }}
            <a style="color: #2797ff" download="微信聊天记录" :href="detailObj.wxText">下载文件</a>
          </div> -->
          <div class="link" @click="wxTextClick"><span>点击查看</span></div>
        </div>
        <div v-else></div>
      </div>
      <div class="detailLine flexs">
        <div class="detailTitle">通话记录文件：</div>
        <div v-if="detailObj.urlList && detailObj.urlList.length">
          <div
            class="link"
            v-for="(item, index) in detailObj.urlList"
            :key="index"
          >
            {{ item }}
            <a style="color: #2797ff" :href="item">下载文件</a>
          </div>
        </div>
        <div v-else></div>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog
      title="微信聊天记录"
      :visible.sync="dialogFileVisible"
      :show-close="true"
      center
      width="80%"
    >
      <div class="dialogFileBox" v-loading="dialogLoading">
        <div class="flex-space-between">
          <el-date-picker
            clearable
            v-model="searchObj.time"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="searchClick"
          ></el-date-picker>
          <!-- <el-button type="primary"><a style="color: #fff" download="微信聊天记录" :href="detailObj.wxText">下载</a></el-button> -->
        </div>
        <div class="wxTextBox">
          <div
            class="spaceBetween"
            v-for="(item, index) in wxTextList"
            :key="index"
          >
            <div class="wxTextListBox">
              <div class="time">{{ item.date }}</div>
              <div v-for="(item1, index1) in item.msgcontents" :key="index1">
                {{ item1 }}
              </div>
            </div>
            <div class="wxTextEvaluateBox">
              <div
                class="evaluateBox"
                v-if="
                  item.customChatEvaluateVos &&
                  item.customChatEvaluateVos.length
                "
              >
                <div
                  v-for="(item1, index1) in item.customChatEvaluateVos"
                  :key="index1"
                >
                  <div>{{ item1.evaluatorName }} {{ item1.createTime }}</div>
                  <div>{{ item1.evaluationContent }}</div>
                </div>
              </div>
              <div
                :class="
                  item.customChatEvaluateVos &&
                  item.customChatEvaluateVos.length
                    ? 'borderTop sendEvaluateBox'
                    : 'sendEvaluateBox'
                "
                v-if="isAdmin"
              >
                <div>
                  <el-input
                    @input="inputChange()"
                    type="textarea"
                    :rows="4"
                    placeholder="发表评论"
                    v-model="item.textarea"
                  ></el-input>
                  <div class="justifyEnd sendBtn">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="sendEvaluation(item)"
                      >发送</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import headerTitle from '../../../components/title.vue';
import {
  getCustomFollowUpDetail,
  getChatRecord,
  addEvaluate
} from 'api/customerManagement.js';
export default {
  name: 'CustomerFollowRecordDetail',
  components: {
    headerTitle
  },
  data() {
    return {
      loading: false,
      dialogLoading: false,
      id: '',
      detailObj: {},
      dialogFileVisible: false,
      searchObj: {
        time: []
      },
      isAdmin: false,
      wxTextList: [],
      assignmentTeacherShow: false
    };
  },
  props: {},
  watch: {},
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.assignmentTeacherShow =
      this.$route.query.assignmentTeacherShow || false;
    const patientMsg = JSON.parse(sessionStorage.getItem('patientMsg'));
    if (patientMsg.roleName == '管理员') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.getDetail();
  },
  methods: {
    //
    sendEvaluation(item) {
      if (!item.textarea) {
        this.$message({
          type: 'error',
          message: '请输入评价内容!'
        });
        return false;
      }
      const data = {
        customId: this.detailObj.customId,
        teacherId: this.detailObj.teacherId,
        chatTime: item.date,
        evaluationContent: item.textarea
      };
      this.dialogLoading = true;
      addEvaluate(data).then((res) => {
        this.dialogLoading = false;
        if (res.code == '2000') {
          this.$message({
            type: 'success',
            message: '发送成功！'
          });
          this.searchClick();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    //
    searchClick() {
      const data = {
        customId: this.detailObj.customId,
        teacherId: this.detailObj.teacherId,
        startDate:
          this.searchObj.time && this.searchObj.time[0]
            ? this.searchObj.time[0]
            : '',
        endDate:
          this.searchObj.time && this.searchObj.time[1]
            ? this.searchObj.time[1]
            : ''
      };
      this.dialogLoading = true;
      getChatRecord(data).then((res) => {
        this.dialogLoading = false;
        if (res.code == '2000') {
          this.wxTextList = res.data;
          this.wxTextList.forEach((item) => {
            item.textarea = '';
          });
          this.dialogFileVisible = true;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    //
    wxTextClick() {
      this.searchObj.time = this.$utils.getDeFaultDate(7);
      this.searchClick();
    },
    //
    getDetail() {
      const data = {
        id: this.id
      };
      this.loading = true;
      getCustomFollowUpDetail(data).then((res) => {
        this.loading = false;
        if (res.code == '2000') {
          this.detailObj = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    inputChange() {
      this.$forceUpdate();
    }
  }
};
</script>
<style lang="stylus" scoped>
.customerFollowRecordDetailBox {
  box-sizing: border-box;
  background: #fff;

  .detailDialogBox {
    padding: 20px;

    .lineBox {
      padding-bottom: 30px;

      >div {
        flex: 1;
      }

      .downBtn {
        color: #2797FC;
        margin-left: 13px;
        cursor: pointer;
      }
    }

    .detailLine {
      padding-bottom: 30px;

      .detailTitle {
        width: 130px;
        flex-shrink: 0;
      }

      .link {
        margin-bottom: 10px;

        >span {
          color: #2797ff;
          cursor: pointer;
        }
      }
    }
  }

  .wxTextBox {
    padding-top: 20px;

    >div {
      border: solid 1px #ccc;
      border-bottom: none;
      line-height: 24px;

      .wxTextListBox, .wxTextEvaluateBox {
        flex: 1;
      }

      .wxTextListBox {
        border-right: solid 1px #ccc;
        padding: 20px;

        .time {
          font-size: 18px;
          padding-bottom: 10px;
        }
      }

      .wxTextEvaluateBox {
        padding: 20px 0;

        .evaluateBox {
          padding: 0 20px 20px;
        }

        .sendEvaluateBox {
          padding: 20px 20px 0;

          .sendBtn {
            padding-top: 10px;
          }
        }

        .borderTop {
          border-top: solid 1px #ccc;
        }
      }
    }

    >div:last-child {
      border-bottom: solid 1px #ccc;
    }
  }
}
</style>