const dictionary = {
  intentionStage: [ // 意向阶段
    '高中', '本科', '研究生'
  ],
  allocationList: [ // 是否分配
    {
      name: '是',
      code: '0'
    },
    {
      name: '否',
      code: '1'
    }
  ],
  followUpStatus: [ // 跟进状态
    {
      name: '未跟进',
      code: '0'
    },
    {
      name: '已跟进',
      code: '1'
    },
    {
      name: '超过7天未跟进资源',
      code: '2'
    },
    {
      name: '超过15天未跟进资源',
      code: '3'
    },
    {
      name: '超过30天未跟进资源',
      code: '4'
    }
  ],
  resourceStatus: [ // 资源状态
    {
      name: '新客未回访',
      code: '0'
    },
    {
      name: '潜在客户',
      code: '1'
    },
    {
      name: '深度意向',
      code: '2'
    },
    {
      name: '长线客户',
      code: '3'
    },
    {
      name: '已签约资源',
      code: '4'
    },
    {
      name: '退回资源',
      code: '5'
    },
    {
      name: '联系不到',
      code: '6'
    },
    {
      name: '放弃出国',
      code: '7'
    }
  ],
  followUpStatus1: [{
      name: '全部',
      code: ''
    },
    {
      name: '未跟进',
      code: 0
    },
    {
      name: '已跟进',
      code: 1
    }
  ], // 跟进状态
  taskType: [ // 任务类型
    {
      name: '新客首访',
      code: '0'
    },
    {
      name: '下次回访',
      code: '1'
    },
  ],
  contactType: [ // 联系类型
    {
      name: '电话联系',
      code: '0'
    },
    {
      name: '微信联系',
      code: '1'
    },
    {
      name: '面谈',
      code: '2'
    },
  ],
  sourceChannel: ['系统班', '小班', '私课', '第三方'], // 来源渠道
  smallClass: ['黄金支点课', '升学密卷课', '家庭教育认知课程'], // 小课选项
  privateClass: ['抖音', '视频号', '粉丝群', '私聊'], // 私课选项
  thirdParty: ['本地', '同事介绍', '熟人推荐'], // 第三方选项
  resourceTeacherStatus: [{
    name: '待反馈',
    code: '0'
  }, {
    name: '已签约资源',
    code: '1'
  }, {
    name: '退回资源',
    code: '2'
  }], // 客户信息录入中资源状态筛选
};

export default dictionary;
