<template>
  <div class="searchBigBox">
    <div class="searchBox">
      <!-- 客户名称 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">客户名称：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.name"
            placeholder="请输入客户名称"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 意向阶段 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">意向阶段：</div>
        <div class="searchInputBox">
          <el-select
            size="small"
            clearable
            v-model="searchObj.intentionStage"
            placeholder="请选择意向阶段"
            @change="searchClick"
          >
            <el-option
              v-for="(item, index) in intentionStage"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- 意向国家/地区 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">意向国家/地区：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.intentionCountry"
            placeholder="请输入意向国家/地区"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 计划入学季 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">计划入学季：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.planStudentDate"
            placeholder="请输入计划入学季"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 手机号 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">手机号：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.phone"
            placeholder="请输入手机号"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 电子邮件 -->
      <!-- <div class="searchInputBigBox">
        <div class="searchInputLabel">电子邮件：</div>
        <div class="searchInputBox">
          <el-input size="small" clearable v-model="searchObj.email" placeholder="请输入电子邮件" @keydown.enter.native="searchClick"></el-input>
        </div>
      </div> -->
      <!-- 是否分配 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">是否分配：</div>
        <div class="searchInputBox">
          <el-select
            size="small"
            clearable
            v-model="searchObj.teacherFlag"
            placeholder="请选择是否分配"
            @change="searchClick"
          >
            <el-option
              v-for="(item, index) in allocationList"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- 分配老师 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">分配老师：</div>
        <div class="searchInputBox">
          <el-input
            size="small"
            clearable
            v-model="searchObj.teacherName"
            placeholder="请输入分配老师"
            @keydown.enter.native="searchClick"
          ></el-input>
        </div>
      </div>
      <!-- 资源状态 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">资源状态：</div>
        <div class="searchInputBox">
          <el-select
            size="small"
            clearable
            v-model="searchObj.customTypeFlag"
            placeholder="请选择资源状态"
            @change="searchClick"
          >
            <el-option
              v-for="(item, index) in resourceTeacherStatus"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
      </div>
        <!-- 创建时间 -->
        <div class="searchInputBigBox">
        <div class="searchInputLabel">创建时间：</div>
        <div class="searchInputBox1">
          <el-date-picker
            v-model="searchObj.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="searchClick"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="buttonBox">
      <div class="button">
        <el-button type="primary" size="small" @click="searchClick" round>
          查询
        </el-button>
      </div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="addClick"
          round
        >
          新增客户
        </el-button>
      </div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="allocationClick"
          round
        >
          分配老师
        </el-button>
      </div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          style="background-color: #25c484; border: none"
          @click="importClick"
          round
        >
          批量导入
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  data() {
    return {};
  },
  props: {
    searchObj: Object,
    intentionStage: Array,
    allocationList: Array,
    resourceTeacherStatus: Array
  },
  methods: {
    searchClick() {
      this.$emit('searchClick');
    },
    addClick() {
      this.$emit('addClick');
    },
    allocationClick() {
      this.$emit('allocationClick', 'multiple');
    },
    importClick() {
      this.$emit('importClick');
    }
  }
};
</script>
<style lang="stylus" scoped>
.searchBigBox {
  // display: flex;
  // justify-content: space-between;
  padding-bottom: 20px;

  .searchBox {
    display: flex;
    flex-wrap: wrap;

    .searchInputBigBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 10px;
      margin-bottom: 10px;

      .searchInputLabel {
        font-size: 14px;
        color: #333333;
      }

      .searchInputBox {
        >>> .el-input__inner {
          height: 36px;
        }
      }
    }
  }

  .buttonBox {
    display: flex;
    justify-content: flex-end;

    .button .el-button {
      width: 80px;
      height: 28px;
      line-height: 28px;
      padding: 0;
      margin-right: 11px;
    }
  }
}
</style>