<template>
  <div class="customerInformationInputDetailBox">
    <headerTitle
      title="客户信息"
      :titles="titles"
      :showBtn="true"
    ></headerTitle>
    <div class="customerInformationInputDetail">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="客户详情" name="customerInformationDetail">
          <customerInformationDetail
            :activeName="activeName"
            :type="type"
            :customId="customId"
            :allocationRouterShow="true"
          ></customerInformationDetail>
        </el-tab-pane>
        <el-tab-pane
          label="跟进记录"
          name="followUpRecords"
          v-if="type != 'add'"
        >
          <followUpRecords
            :activeName="activeName"
            :assignmentTeacherShow="true"
          ></followUpRecords>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import headerTitle from '../../../components/title.vue';
import customerInformationDetail from '../../../components/customerInformation/customerInformationDetail.vue';
import followUpRecords from '../../../components/customerInformation/followUpRecords.vue';
export default {
  name: 'CustomerInformationInputDetail',
  components: {
    headerTitle,
    customerInformationDetail,
    followUpRecords
  },
  data() {
    return {
      type: '',
      titles: [],
      activeName: 'customerInformationDetail',
      customId: ''
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.activeName =
      sessionStorage.getItem('activeName') || 'customerInformationDetail';
    this.type = this.$route.query.type;
    this.customId = this.$route.query.customId;
    switch (this.type) {
      case 'add':
        this.titles = ['新增客户'];
        break;
      case 'edit':
        this.titles = ['编辑客户信息'];
        break;
      case 'detail':
        this.titles = ['客户详情'];
        break;
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem('activeName');
  },
  methods: {
    handleClick() {
      sessionStorage.setItem('activeName', this.activeName);
    }
  }
};
</script>

<style lang="stylus" scoped>
.customerInformationInputDetailBox {
  box-sizing: border-box;
  font-size: 14px;

  .customerInformationInputDetail {
    background: #fff;
    padding: 0 20px;
  }
}
</style>