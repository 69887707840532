<template>
  <div class="customerInformationDetail" v-loading="loading">
    <div class="contentBox">
      <div class="itemBox">
        <div class="title">客户信息</div>
        <div class="lineBox">
          <!--  -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel flexs-alignCenter">
                <span>*</span>
                客户名称：
              </div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.name"
                placeholder="请输入客户名称名称"
              ></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">
                <span>*</span>
                意向阶段：
              </div>
              <el-select
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.intentionStage"
                placeholder="请选择意向阶段"
              >
                <el-option
                  v-for="(item, index) in intentionStage"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">
                <span>*</span>
                意向国家/地区 ：
              </div>
              <!-- <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.intentionCountry"
                placeholder="请输入意向国家/地区"
              ></el-input> -->
              <el-select
                filterable
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.intentionCountry"
                placeholder="请选择意向国家/地区"
              >
                <el-option
                  v-for="(item, index) in labelList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">
                <span>*</span>
                计划入学季：
              </div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.planStudentDate"
                placeholder="请输入计划入学季"
              ></el-input>
            </div>
          </div>
          <!--  -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">在读院校：</div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.school"
                placeholder="请输入在读院校"
              ></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">在读年级：</div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.grade"
                placeholder="请输入在读年级"
              ></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">在读专业：</div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.speciality"
                placeholder="请输入在读专业"
              ></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">在读成绩：</div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.score"
                placeholder="请输入在读成绩"
              ></el-input>
            </div>
          </div>
          <!--  -->
          <div
            class="flexs-alignCenter inputBigBox"
            style="align-items: flex-start"
          >
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">标化成绩：</div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.scoreStandard"
                placeholder="请输入标化成绩"
              ></el-input>
            </div>
            <div class="inputBox flexs-alignCenter" style="margin-bottom: 40px">
              <div class="inputLabel">
                <span>*</span>
                手机号：
              </div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.phone"
                placeholder="请输入手机号"
              ></el-input>
              <i
                class="el-icon-question"
                style="color: #ff0000; margin-left: 6px; cursor: pointer"
                @click="tishiImageClick"
              ></i>
              <div class="inputBoxTishi">
                需前往企业微信中为客户备注电话号码，否则无法拉取企业微信聊天记录！
              </div>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">
                <span>*</span>
                微信号：
              </div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.wxNumber"
                placeholder="请输入微信号"
              ></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">电子邮件：</div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.email"
                placeholder="请输入电子邮件"
              ></el-input>
            </div>
          </div>
          <!--  -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">客户简介：</div>
              <el-input
                type="textarea"
                :rows="3"
                resize="none"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.content"
                placeholder="请输入客户简介"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="title">资源信息</div>
        <div class="lineBox">
          <!--  -->
          <div class="flexs-alignCenter inputBigBox">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel flexs-alignCenter">
                <span>*</span>
                来源渠道：
              </div>
              <el-select
                size="medium"
                :disabled="type == 'detail'"
                @change="channelChange"
                clearable
                v-model="dataObj.sourceChannel"
                placeholder="请选择来源渠道"
              >
                <el-option
                  v-for="(item, index) in sourceChannel"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel flexs-alignCenter">
                <span>*</span>
                渠道明细：
              </div>
              <el-select
                @change="channelDetailChange"
                v-show="dataObj.sourceChannel != '系统班'"
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.channelDetail"
                placeholder="请选择渠道明细"
              >
                <el-option
                  v-for="(item, index) in channelDetailList"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
              <el-input
                @input="channelDetailChange"
                v-show="dataObj.sourceChannel == '系统班'"
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.channelDetail"
                placeholder="请输入系统班号"
              ></el-input>
            </div>
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">数据提供方：</div>
              <el-input
                size="medium"
                :disabled="type == 'detail'"
                clearable
                v-model="dataObj.dataProvider"
                placeholder="请输入数据提供方"
              ></el-input>
            </div>
            <div class="inputBox flexs-alignCenter" v-if="dataObj.createName">
              <div class="inputLabel">客服老师：</div>
              <el-button
                type="text"
                @click="toDetails(dataObj.createName, '')"
              >
                {{ dataObj.createName }}
              </el-button>
            </div>
            <!-- 占位 -->
            <div class="inputBox flexs-alignCenter"></div>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="title">分配信息</div>
        <div class="lineBox">
          <!--  -->
          <div class="flexs-alignCenter inputBigBox" v-if="type != 'detail'">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">分配老师：</div>
              <el-select
                value-key="id"
                multiple
                @change="teacherChange"
                :loading="teacherSearchLoading"
                remote
                filterable
                :remote-method="remoteMethod"
                :disabled="type == 'detail'"
                size="medium"
                clearable
                v-model="dataObj.allocTeacher"
                placeholder="请选择分配老师"
              >
                <el-option
                  v-for="item in teacherList"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!--  -->
          <div
            class="flexs-alignCenter inputBigBox"
            v-if="
              type == 'detail' &&
              allocationRouterShow &&
              dataObj &&
              dataObj.allocTeacher &&
              dataObj.allocTeacher.length
            "
          >
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">分配老师：</div>
              <div>
                <el-button
                  type="text"
                  v-for="(item, index) in dataObj.allocTeacher"
                  :key="index"
                  @click="toDetails('', item.name)"
                >
                  {{ item.name }}
                </el-button>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="flexs-alignCenter inputBigBox" v-if="dataObj.allocTime">
            <div class="inputBox flexs-alignCenter">
              <div class="inputLabel">分配时间：</div>
              {{ dataObj.allocTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <el-button v-if="type != 'detail'" type="primary" round @click="save">
        保存
      </el-button>
    </footer>
    <!--  -->
    <el-dialog
      title="手机号备注步骤"
      :visible.sync="tishiImageShow"
      width="80%"
    >
      <el-image
        style="width: 100%"
        src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/67tool-2024-11-08%2015_28_43.jpg"
        :preview-src-list="[
          'https://jyt-new.oss-cn-beijing.aliyuncs.com/other/67tool-2024-11-08%2015_28_43.jpg'
        ]"
      >
      </el-image>
    </el-dialog>
  </div>
</template>
<script>
import dictionary from '@/utils/dictionary.js';
import {
  editCustomerDetail,
  addCustomer,
  getCustomerDetail,
  customerVaild
} from 'api/customerManagement.js'; //
import { getAccountManagementList } from 'api/systemManagement.js';
import { dicListByCode } from 'api/index.js';
export default {
  name: 'CustomerInformationDetail',
  components: {},
  data() {
    return {
      intentionStage: dictionary.intentionStage,
      sourceChannel: dictionary.sourceChannel,
      smallClass: dictionary.smallClass,
      privateClass: dictionary.privateClass,
      thirdParty: dictionary.thirdParty,
      teacherList: [],
      // sourceChannelList: [],
      channelDetailList: [],
      dataObj: {
        allocTeacher: []
      },
      checkObj: {
        name: '客户名称名称',
        phone: '客户手机号',
        wxNumber: '客户微信号',
        sourceChannel: '来源渠道',
        channelDetail: '渠道明细',
        intentionStage: '意向阶段',
        intentionCountry: '意向国家/地区',
        planStudentDate: '计划入学季'
      },
      loading: false,
      teacherSearchLoading: false,
      tishiImageShow: false,
      labelList: []
    };
  },
  props: {
    type: String,
    activeName: String,
    customId: String,
    allocationRouterShow: Boolean // 是否展示可点击跳转的分配老师
  },
  watch: {
    activeName: {
      deep: true, // 深度监听每一个属性值, 如果是对象可以深度监听
      immediate: true, // 进入页面后立即自动调用handler函数
      handler(newValue) {
        if (newValue == 'customerInformationDetail') {
          this.getTeacherList();
          this.getCodeList();
        }
      }
    },
    customId: {
      deep: true, // 深度监听每一个属性值, 如果是对象可以深度监听
      immediate: true, // 进入页面后立即自动调用handler函数
      handler(newValue) {
        if (newValue) {
          this.getDetails();
          this.getCodeList();
        }
      }
    }
  },
  methods: {
    toDetails(userName, teacherName) {
      sessionStorage.setItem('customerInformationSearch', {
        createName: userName,
        teacherName: teacherName
      });
      this.$router.replace({
        path: '/customerInformation'
      });
    },
    tishiImageClick() {
      this.tishiImageShow = !this.tishiImageShow;
    },
    channelChange(val) {
      this.dataObj.channelDetail = '';
      if (val == '小班') {
        this.channelDetailList = this.smallClass;
      } else if (val == '私课') {
        this.channelDetailList = this.privateClass;
      } else if (val == '第三方') {
        this.channelDetailList = this.thirdParty;
      }
    },
    //
    channelDetailChange(val) {
      this.$forceUpdate();
    },
    // 点击保存
    save() {
      let flag = true;
      for (const i in this.checkObj) {
        if (!this.dataObj[i]) {
          flag = false;
          this.$message({
            type: 'error',
            message: `${this.checkObj[i]}不可为空！`
          });
          return;
        }
      }
      if (!flag) {
        return false;
      }
      // if (!this.$utils.nameRuleCheck(this.dataObj.name)) {
      //   this.$message({
      //     type: 'error',
      //     message: '客户名称名称输入格式有误！'
      //   });
      //   return false;
      // }
      // if (!this.$utils.wxNumberRuleCheck(this.dataObj.wxNumber)) {
      //   this.$message({
      //     type: 'error',
      //     message: '客户微信号输入格式有误！'
      //   });
      //   return false;
      // }
      if (!this.$utils.phoneRuleCheck(this.dataObj.phone)) {
        this.$message({
          type: 'error',
          message: '客户手机号输入格式有误！'
        });
        return false;
      }
      if (
        this.dataObj.email &&
        !this.$utils.emailRuleCheck(this.dataObj.email)
      ) {
        this.$message({
          type: 'error',
          message: '客户邮箱输入格式有误！'
        });
        return false;
      }
      //
      this.loading = true;
      customerVaild(this.dataObj)
        .then((res) => {
          if (res.code == 2000) {
            this.submitClick(this.dataObj);
          } else {
            this.loading = false;
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submitClick(data) {
      if (this.type == 'add') {
        addCustomer(data).then((res) => {
          this.loading = false;
          if (res.code == '2000') {
            this.$message({
              type: 'success',
              message: '新增成功！'
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      } else {
        editCustomerDetail(data).then((res) => {
          this.loading = false;
          if (res.code == '2000') {
            this.$message({
              type: 'success',
              message: '编辑成功！'
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }
    },
    remoteMethod(query) {
      if (query !== '') {
        this.teacherSearchLoading = true;
        getAccountManagementList({
          ifPage: false,
          roleId: 2,
          phone: query
        }).then((res) => {
          this.teacherSearchLoading = false;
          if (res.code == '2000') {
            this.teacherList = res.data.list;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }
    },
    teacherChange(val) {},
    // 分配老师列表
    getTeacherList() {
      this.loading = true;
      getAccountManagementList({ ifPage: false, roleId: 2, phone: '' }).then(
        (res) => {
          this.loading = false;
          if (res.code == '2000') {
            this.teacherList = res.data.list;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        }
      );
    },
    // 获取详情
    getDetails() {
      getCustomerDetail({ id: this.customId }).then((res) => {
        this.loading = false;
        if (res.code == '2000') {
          this.dataObj = res.data;
          if (
            this.dataObj.sourceChannel &&
            this.dataObj.sourceChannel != '系统班'
          ) {
            const val = this.dataObj.sourceChannel;
            if (val == '小班') {
              this.channelDetailList = this.smallClass;
            } else if (val == '私课') {
              this.channelDetailList = this.privateClass;
            } else if (val == '第三方') {
              this.channelDetailList = this.thirdParty;
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    getCodeList() {
      dicListByCode({ code: 'YXGJ' }).then((res) => {
        if (res.code == '2000') {
          this.labelList = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.customerInformationDetail {
  .contentBox {
    // padding: 0 20px;
    .itemBox {
      border-bottom: 1px solid #EEEEEE;
      padding: 20px 0;
      padding-bottom: 40px;

      .title {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
        margin-bottom: 15px;
      }

      .inputBigBox {
        margin-bottom: 15px;

        .inputBox {
          flex: 1;
          position: relative;

          .inputBoxTishi {
            width: 100%;
            color: rgb(255, 0, 0);
            font-size: 12px;
            position: absolute;
            top: 100%;
            left: 0;
            padding: 6px;
            padding-bottom: 0;
            box-sizing: border-box;
          }

          .inputLabel {
            flex-shrink: 0;

            span {
              color: #FF0000;
            }
          }

          >>> .el-input {
            width: auto;
          }

          >>> .el-input__inner {
            width: 150px;
          }
        }
      }

      .inputBigBox:last-of-type {
        margin-bottom: 0;
      }
    }

    .itemBox:last-child {
      border: none;
    }
  }

  footer {
    padding: 20px 0;

    >>>.el-button {
      width: 250px;
    }
  }
}
</style>