<template>
  <div class="tableBox">
    <!-- 表格 -->
    <el-table :data="dataList" stripe style="width: 100%">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        align="center"
      ></el-table-column>
      <el-table-column prop="userName" label="销售名称" align="center">
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: #4095e5"
            @click="toDetail(scope.row, '', '')"
          >
            {{ scope.row.userName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="totalTaskNum" label="总分配资源" align="center">
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: #4095e5"
            @click="toDetail(scope.row, '', '')"
          >
            {{ scope.row.totalTaskNum }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="needsFollowUpCount"
        label="未跟进资源"
        align="center"
      >
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: #4095e5"
            @click="toDetail(scope.row, 0, '')"
          >
            {{ scope.row.needsFollowUpCount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="followUpCount"
        label="已跟进资源"
        align="center"
      >
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: #4095e5"
            @click="toDetail(scope.row, 1, '')"
          >
            {{ scope.row.followUpCount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="signedCustomerCount"
        label="签约资源"
        align="center"
      >
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: #4095e5"
            @click="toDetail(scope.row, '', '4')"
          >
            {{ scope.row.signedCustomerCount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="totalSignedAmount" label="签约金额" align="center">
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: #4095e5"
            @click="toDetail(scope.row, '', '4')"
          >
            {{ scope.row.totalSignedAmount }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'TableBox',
  components: {},
  data() {
    return {};
  },
  props: {
    dataList: Array,
    pagenum: Number,
    pageSize: Number,
    datetime: Array
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    },
    toDetail(row, followUpStatusFlag, customType) {
      sessionStorage.setItem('customerInformationSearch', {
        time: this.datetime,
        teacherName: row.userName,
        followUpStatusFlag: followUpStatusFlag,
        customType: customType
      });
      this.$router.push({
        path: '/customerInformation'
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.tableBox {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>