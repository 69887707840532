<template>
  <div class="statisticsBox">
    <headerTitle title="数据统计"></headerTitle>
    <div class="statistics">
      <div class="searchBox">
        <div class="searchInputBigBox">
          <div class="searchInputLabel">时间段：</div>
          <div class="searchInputBox">
            <el-date-picker
              @change="searchClick"
              clearable
              v-model="searchObj.time"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="statisticsChart">
        <!-- header -->
        <div class="headerBigBox">
          <div class="headerText">客户统计</div>
          <div class="headerContent">客户总数：{{ threeTotal }}个</div>
        </div>
        <!--  -->
        <div class="lineBox">
          <!-- <div class="leftBox">
            <div class="title">
              <img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1.png" alt="" />
              <span>客户增长趋势</span>
            </div>
            <graph :chartData="chartData1"></graph>
          </div> -->
          <div class="leftBox">
            <div class="title">
              <img
                src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1%20(1).jpg"
                alt=""
              />
              <span>客户来源</span>
            </div>
            <circularDiagrams
              :chartData="chartData4"
              v-loading="loading"
            ></circularDiagrams>
          </div>
          <div class="rightBox">
            <div class="title">
              <img
                src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1%20(2).jpg"
                alt=""
              />
              <span>客户意向地区分布</span>
            </div>
            <circularDiagram7
              :chartData="chartData7"
              v-loading="loading"
            ></circularDiagram7>
          </div>
        </div>
        <!--  -->
        <div class="lineBox marginBotton20">
          <div>
            <div class="title borderBottom1">
              <img
                src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1%20(3).jpg"
                alt=""
              />
              <span>客服数据统计</span>
            </div>
            <tableBox2
              :dataList="list2"
              :pagenum="pagenum2"
              :pageSize="10"
              v-loading="loading2"
              :datetime="searchObj.time"
            ></tableBox2>
            <!-- 分页 -->
            <paging
              :pageall="pageall2"
              :pagenum="pagenum2"
              @pageclick="pageclick2"
            ></paging>
          </div>
        </div>
        <!-- header -->
        <div class="headerBigBox">
          <div class="headerText">资源统计</div>
          <div class="headerContent">重复分配资源数：{{ countByRepeatAlloc }}条</div>
        </div>
        <!--  -->
        <div class="lineBox">
          <div class="leftBox">
            <div class="title">
              <img
                src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1%20(4).jpg"
                alt=""
              />
              <span>资源分配情况</span>
            </div>
            <circularDiagram
              :chartData="chartData3"
              v-loading="loading"
            ></circularDiagram>
          </div>
          <div class="rightBox">
            <div class="title">
              <img
                src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1%20(5).jpg"
                alt=""
              />
              <span>资源状态情况</span>
            </div>
            <histogram :chartData="chartData2" v-loading="loading"></histogram>
          </div>
        </div>
        <!--  -->
        <div class="lineBox marginBotton20">
          <div>
            <div class="title borderBottom1">
              <img
                src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1%20(6).jpg"
                alt=""
              />
              <span>销售数据统计</span>
            </div>
            <tableBox1
              :dataList="list1"
              :pagenum="pagenum1"
              :pageSize="10"
              :datetime="searchObj.time"
              v-loading="loading1"
            ></tableBox1>
            <!-- 分页 -->
            <paging
              :pageall="pageall1"
              :pagenum="pagenum1"
              @pageclick="pageclick1"
            ></paging>
          </div>
        </div>
        <!-- header -->
        <div class="headerBigBox">
          <div class="headerText">服务群数统计</div>
        </div>
        <!--  -->
        <div class="lineBox">
          <div>
            <div class="title">
              <img
                src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/statisticsIcon1%20(7).jpg"
                alt=""
              />
              <span>销售服务群数排名</span>
            </div>
            <tableBox
              :dataList="dataList"
              :pagenum="pagenum"
              :pageSize="pageSize"
              v-loading="loading"
            ></tableBox>
            <!-- 分页 -->
            <paging
              :pageall="pageall"
              :pagenum="pagenum"
              @pageclick="pageclick"
            ></paging>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  customStatistics,
  getList1,
  getList2
} from 'api/customerManagement.js';
import paging from '../../../components/paging.vue';
import headerTitle from '../../../components/title.vue';
import tableBox from './component/table.vue';
import tableBox1 from './component/table1.vue';
import tableBox2 from './component/table2.vue';
import circularDiagram from './component/circularDiagram.vue';
import circularDiagrams from './component/circularDiagrams.vue';
import circularDiagram7 from './component/circularDiagram7.vue';
// import graph from './component/graph.vue';
import histogram from './component/histogram.vue';
export default {
  name: 'Statistics',
  components: {
    paging,
    headerTitle,
    circularDiagram,
    circularDiagrams,
    circularDiagram7,
    histogram,
    tableBox,
    tableBox1,
    tableBox2
  },
  data() {
    return {
      loading: false,
      loading1: false,
      loading2: false,
      searchObj: {
        time: []
      },
      pageall: 0,
      pagenum: 1,
      pageSize: 10,
      dataList: [],
      chartData1: {},
      chartData2: {},
      chartData3: [],
      chartData4: [],
      threeTotal: 0,
      countByRepeatAlloc: 0,
      chartData7: [],
      flag: true,
      pageall1: 0,
      pagenum1: 1,
      pageall2: 0,
      pagenum2: 1,
      list1: [],
      list2: []
    };
  },
  props: {},
  watch: {},
  computed: {},
  created() {
    this.searchObj.time = this.$utils.getDeFaultDate(7);
    this.getData();
    this.getDataList1();
    this.getDataList2();
  },
  mounted() {},
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getData();
    },
    pageclick1(val) {
      this.pagenum1 = val;
      this.getDataList1();
    },
    pageclick2(val) {
      this.pagenum2 = val;
      this.getDataList2();
    },
    searchClick() {
      this.pageclick(1);
      this.pageclick1(1);
      this.pageclick2(1);
    },
    getData() {
      const data = {
        startDate:
          this.searchObj.time && this.searchObj.time[0]
            ? this.searchObj.time[0]
            : '',
        endDate:
          this.searchObj.time && this.searchObj.time[1]
            ? this.searchObj.time[1]
            : '',
        ifPage: true,
        pageSize: this.pageSize,
        pageNum: this.pagenum
      };
      this.loading = true;
      customStatistics(data).then((res) => {
        this.loading = false;
        if (res.code == '2000') {
          console.log(res.data, '============');
          const chartData3 = [];
          const chartData4 = [];
          const chartData7 = [];
          res.data.three.forEach((item) => {
            chartData3.push({
              value: item.y,
              name: item.x
            });
          });
          res.data.four.forEach((item) => {
            chartData4.push({
              value: item.y,
              name: item.x
            });
          });
          res.data.seven.forEach((item) => {
            chartData7.push({
              value: item.y,
              name: item.x
            });
          });

          this.chartData1 = res.data.one;
          this.chartData2 = res.data.two;
          this.threeTotal = res.data.threeTotal;
          this.countByRepeatAlloc = res.data.countByRepeatAlloc;
          this.chartData3 = chartData3;
          this.chartData4 = chartData4;
          this.chartData7 = chartData7;
          this.dataList = (res.data.five && res.data.five.list) || [];
          this.pageall = (res.data.five && res.data.five.total) || 0;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    getDataList1() {
      const data = {
        startDate:
          this.searchObj.time && this.searchObj.time[0]
            ? this.searchObj.time[0]
            : '',
        endDate:
          this.searchObj.time && this.searchObj.time[1]
            ? this.searchObj.time[1]
            : '',
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum1
      };
      this.loading1 = true;
      getList1(data)
        .then((res) => {
          this.loading1 = false;
          if (res.code == 2000) {
            this.list1 = res.data.list || [];
            this.pageall1 = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading1 = false;
        });
    },
    getDataList2() {
      const data = {
        startDate:
          this.searchObj.time && this.searchObj.time[0]
            ? this.searchObj.time[0]
            : '',
        endDate:
          this.searchObj.time && this.searchObj.time[1]
            ? this.searchObj.time[1]
            : '',
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum2
      };
      this.loading2 = true;
      getList2(data)
        .then((res) => {
          this.loading2 = false;
          if (res.code == 2000) {
            this.list2 = res.data.list || [];
            this.pageall2 = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    }
  }
};
</script>
<style lang="stylus" scoped>
.statistics {
  padding: 20px;
  box-sizing: border-box;

  .searchBox {
    display: flex;
    flex-wrap: wrap;

    .searchInputBigBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 20px;
      margin-bottom: 10px;

      .searchInputLabel {
        font-size: 14px;
        color: #333333;
      }

      .searchInputBox {
        >>> .el-input__inner {
          height: 36px;
        }
      }
    }
  }

  .headerBigBox {
    padding: 16px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #ffffff;
    border-bottom: solid 1px #EEEEEE;
    border-radius: 8px 8px 0 0;

    .headerText {
      border-left: 3px solid #5574D8;
      padding-left: 8px;
      box-sizing: border-box;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }

    .headerContent {
      font-size: 16px;
      color: #333333;
      margin-left: 26px;
    }
  }

  .lineBox {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding: 0 20px;
      padding-bottom: 14px;
      margin-bottom: 20px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }

    .borderBottom1 {
      border-bottom: solid 1px #EEEEEE;
    }

    >div {
      background: #fff;
      padding: 20px 0;
      box-sizing: border-box;
      width: 100%;
    }

    .leftBox, .rightBox {
      width: 50%;
      border-bottom: solid 1px #EEEEEE;
    }
  }

  .marginBotton20 {
    margin-bottom: 20px;
  }
}
</style>