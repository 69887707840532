<template>
  <div class="followUpRecords" v-loading="loading">
    <div class="searchBox">
      <!-- 上次回访时间段筛选 -->
      <div class="searchInputBigBox">
        <div class="searchInputLabel">上次回访时间段筛选：</div>
        <div class="searchInputBox">
          <el-date-picker
            clearable
            v-model="searchObj.time"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="pageclick(1)"
          ></el-date-picker>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table :data="dataList" stripe style="width: 100%">
      <el-table-column
        type="index"
        :index="indexMethod"
        label="序号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="teacherName"
        label="分配老师"
        align="center"
        v-if="assignmentTeacherShow"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="上次回访时间"
        align="center"
      ></el-table-column>
      <el-table-column prop="customType" label="资源状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.customType | customTypeFilter }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="contactType" label="联系类型" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.contactType | contactTypeFilter }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="content" label="联系内容" align="center">
      </el-table-column>
      <el-table-column
        prop="revisitTime"
        label="提醒下次回访时间"
        align="center"
      ></el-table-column>
      <el-table-column fixed="right" prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="detailClick(scope.row)">
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <paging
      :pageall="pageall"
      :pagenum="pagenum"
      @pageclick="pageclick"
    ></paging>
    <!-- 详情弹窗 -->
    <el-dialog
      title="跟进记录详情"
      :visible.sync="detailDialogVisible"
      :show-close="true"
      center
      width="60%"
    >
      <div class="detailDialogBox">
        <div class="flexs-alignCenter lineBox">
          <div>上次回访时间：{{ detailObj.createTime || '' }}</div>
          <div>提醒下次回访时间：{{ detailObj.revisitTime || '' }}</div>
        </div>
        <div class="flexs-alignCenter lineBox">
          <div>资源状态：{{ detailObj.customType | customTypeFilter }}</div>
          <div>联系类型：{{ detailObj.contactType | contactTypeFilter }}</div>
        </div>
        <div class="flexs-alignCenter lineBox">
          <div>签约金额：{{ detailObj.planBidAmount || 0 }}元</div>
          <div>签约时间：{{ detailObj.planTime || '' }}</div>
        </div>
        <div class="lineBox">竞签机构：{{ detailObj.institution || '' }}</div>
        <div class="lineBox">联系内容：{{ detailObj.content || '' }}</div>
        <div class="detailLine flexs">
          <div class="detailTitle">微信聊天记录：</div>
          <div v-if="detailObj.wxText">
            <div class="link">
              {{ detailObj.wxText }}
              <a
                style="color: #2797ff"
                download="微信聊天记录"
                :href="detailObj.wxText"
                >下载文件</a
              >
            </div>
          </div>
          <div v-else></div>
        </div>
        <div class="detailLine flexs">
          <div class="detailTitle">通话记录聊天记录：</div>
          <div v-if="detailObj.urlList && detailObj.urlList.length">
            <div
              class="link"
              v-for="(item, index) in detailObj.urlList"
              :key="index"
            >
              {{ item }}
              <a style="color: #2797ff" :href="item">下载文件</a>
            </div>
          </div>
          <div v-else></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogFileVisible"
      title="文件预览"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <iframe
        v-if="detailObj.wxText"
        :src="`https://view.officeapps.live.com/op/view.aspx?src=${detailObj.wxText}`"
        frameborder="0"
        width="100%"
        height="500px"
      ></iframe>
      <!-- <vue-office-docx @error="errorHandler" v-if="docxUrl" :src="docxUrl" style="height: 100vh" /> -->
    </el-dialog>
  </div>
</template>
<script>
import { getCustomFollowUpRecord } from 'api/customerManagement.js';
import paging from '../paging.vue';
export default {
  name: 'FollowUpRecords',
  components: {
    paging
  },
  data() {
    return {
      loading: false,
      searchObj: {
        time: []
      },
      pageall: 0,
      pagenum: 1,
      pageSize: 10,
      dataList: [],
      customId: '', // 客户id
      id: '', // 任务id
      detailDialogVisible: false,
      dialogFileVisible: false,
      detailObj: {}
    };
  },
  props: {
    activeName: String,
    type: String,
    assignmentTeacherShow: Boolean
  },
  watch: {
    activeName: {
      deep: true, // 深度监听每一个属性值, 如果是对象可以深度监听
      immediate: true, // 进入页面后立即自动调用handler函数
      handler(newValue) {
        if (newValue == 'followUpRecords') {
          this.pageclick(1);
        }
      }
    }
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.customId = this.$route.query.customId;
  },
  methods: {
    downLoadFile(data) {
      // const xhr = new XMLHttpRequest();
      // xhr.open('GET', url, true);
      // xhr.responseType = 'blob';
      // xhr.upload.onprogress = e => {
      //   if (e.lengthComputable) {
      //     const progress = e.loaded / e.total;
      //     console.log('文件上传进度是', progress);
      //   }
      // };
      // xhr.onload = function () {
      //   const url = window.URL.createObjectURL(xhr.response);
      //   const eleLink = document.createElement('a');
      //   eleLink.href = url;
      //   eleLink.download = `微信聊天文件`;
      //   eleLink.style.display = 'none';
      //   document.body.appendChild(eleLink);
      //   eleLink.click();
      //   document.body.removeChild(eleLink);
      // };
      // xhr.onerror = e => {
      //   console.log('请求错误回调', e);
      //   this.$message.warning('下载文件失败');
      // };
      // xhr.send();
    },
    detailClick(item) {
      this.$router.push({
        path: '/customerFollowRecordDetail',
        query: {
          id: item.id,
          assignmentTeacherShow: this.assignmentTeacherShow
        }
      });
      //
    },
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      const data = {
        ifPage: true,
        pageSize: this.pageSize,
        pageNum: this.pagenum,
        startDate:
          this.searchObj.time && this.searchObj.time[0]
            ? this.searchObj.time[0]
            : '',
        endDate:
          this.searchObj.time && this.searchObj.time[1]
            ? this.searchObj.time[1]
            : ''
        // taskId: '',
        // customId: ''
      };
      if (this.type == 'sellers') {
        // 老师端传的是taskId，管理端传的是客户id
        data.taskId = this.id;
      } else {
        data.customId = this.customId;
      }
      this.loading = true;
      getCustomFollowUpRecord(data).then((res) => {
        this.loading = false;
        if (res.code == '2000') {
          this.dataList = res.data.list;
          this.pageall = res.data.total;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    indexMethod(index) {
      return index + 1 + (this.pagenum - 1) * this.pageSize;
    }
  }
};
</script>
<style lang="stylus" scoped>
.searchBox {
  display: flex;
  flex-wrap: wrap;

  .searchInputBigBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-bottom: 20px;

    .searchInputLabel {
      font-size: 14px;
      color: #333333;
    }

    .searchInputBox {
      >>> .el-input__inner {
        height: 36px;
      }
    }
  }
}

.lineBox {
  padding-bottom: 15px;

  >div {
    flex: 1;
  }

  .downBtn {
    color: #2797FC;
    margin-left: 13px;
    cursor: pointer;
  }
}

.detailLine {
  padding-bottom: 15px;

  .detailTitle {
    width: 130px;
    flex-shrink: 0;
  }

  .link {
    margin-bottom: 10px;
  }
}

>>>.el-dialog {
  border-radius: 16px;
}
</style>