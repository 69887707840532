<template>
  <div class="elMenuBox" :style="{ backgroundColor: backgroundColor }">
    <!-- <div class="loginImg">
      <img class="hospitalIcon" src="https://jyt-new.oss-cn-beijing.aliyuncs.com/images/cartSystem/aside/hospitalIcon.png" alt="" />
      <img class="yunzhenshiIcon" src="https://jyt-new.oss-cn-beijing.aliyuncs.com/images/cartSystem/aside/yunzhenshiIcon.png" alt="" />
    </div> -->
    <el-menu
      :default-active="activeMenu"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :background-color="backgroundColor"
      text-color="#ffffff"
      active-text-color="#FFFFFF"
      :collapse="collapse"
      :router="false"
    >
      <aside-components
        :pageList="pageList"
        :isSubpage="false"
        :collapse="collapse"
        :activeMenu="activeMenu"
        @setActiveUrl="setActiveUrl"
      ></aside-components>
    </el-menu>
  </div>
</template>
<script>
import asideComponents from './asideComponents';
import { getRoleMenuList } from 'api/index.js';
export default {
  name: 'Aside',
  props: {
    isSubpage: {
      type: Boolean,
      default: () => {
        return false;
      }
    }, // 是否是子页面
    parentIde: {
      type: String,
      default: () => {
        return '';
      }
    } // 父页面标识
  },
  data() {
    return {
      backgroundColor: '#303243',
      collapse: false,
      pageList: []
    };
  },
  computed: {
    // activeMenu() {
    //   const route = this.$route;
    //   const { meta, path } = route;
    //   if (meta.activeMenu) {
    //     return meta.activeMenu;
    //   }
    //   return path;
    // }
    activeMenu: {
      get: function () {
        const route = this.$route;
        const { meta, path } = route;
        if (meta.activeMenu) {
          return meta.activeMenu;
        }
        return path;
      },
      set: function () {}
    }
  },
  watch: {
    '$store.state.aside.systemId': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.getMenuData();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    getMenuData() {
      this.pageList = [];
      getRoleMenuList({
        systemId: this.$store.state.aside.systemId
      })
        .then((res) => {
          console.log(res);
          if (res.code == 2000) {
            this.pageList = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    setActiveUrl(url) {
      this.activeMenu = url;
      sessionStorage.setItem('activeUrl', url);
      this.$router
        .push({
          path: url
        })
        .then(() => {})
        .catch(() => {});
    }
  },
  components: {
    asideComponents
  }
};
</script>
<style lang="stylus" scoped>
.loginImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  align-items: center;
  font-size: 14px;
  padding: 30px;
}

.loginImg .hospitalIcon {
  width: 84px;
  height: 73px;
  margin-bottom: 10px;
}

.loginImg .yunzhenshiIcon {
  width: 117px;
  height: 19px;
}

.elMenuBox {
  height: 100%;
  overflow-y: auto;
}

.el-menu {
  border: none;
}

>>> .el-menu-item-group__title {
  padding: 0;
}

>>> .el-menu--collapse>div>div>.el-submenu>.el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

>>>.el-menu-item.is-active {
  background: linear-gradient(90deg, #5B9DF8 0%, #3C7BEC 100%);
  // border-left: solid 8px #9FC1FF;
}

>>>.el-submenu__title i {
  color: #fff;
}

/* 设置滚动条的样式 */
.elMenuBox::-webkit-scrollbar {
  width: 6px;
}

/* 滚动槽 */
.elMenuBox::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
.elMenuBox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
}
</style>